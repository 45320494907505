// adminSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    patients: [],
    prescription: [],
    appointments:[],
    staffs: [],
    payments: [],
    records: [],
    events: [],
    isLoading: true,
    error: null,
};

export const dashboardSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        clearState: (state) => {
            state.patients = [];
            state.prescription = [];
            state.appointments  = [];
            state.staffs = [];
            state.payments = [];
            state.records = [];
            state.events = [];
            return state;
        },
        setPatients: (state, { payload }) => {
            state.patients = payload;
        },
        setPrescriptions: (state, { payload }) => {
            state.prescription = payload;
        },
        setStaffs: (state, { payload }) => {
            state.staffs = payload;
        },
        setNurses: (state, { payload }) => {
            state.nurses = payload;
        },
        setPayments: (state, { payload }) => {
            state.payments = payload;
        },
        setAppointments: (state, { payload }) => {
            state.appointments = payload;
        },
        setRecords: (state, { payload }) => {
            state.records = payload;
        },
        setEvents: (state, { payload }) => {
            state.events = payload;
        },
        isLoadingTrue: (state) => {
            state.isLoading = true;
        },
        isLoadingFalse: (state) => {
            state.isLoading = false;
        },
        addAppointment: (state, { payload }) => {
            state.appointments.push(payload);
        },
    },
});

export const {
    setStaffs,
    setPrescriptions,
    setAppointments,
    setPatients,
    setPayments,
    setRecords,
    setEvents,
    isLoadingFalse,
    isLoadingTrue,
    addAppointment
} = dashboardSlice.actions;


export default dashboardSlice.reducer;