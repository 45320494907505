import React, { useEffect, useState } from 'react';
import { FaUserNurse, FaUserDoctor, FaUserInjured, FaBedPulse, FaCalendarDays, FaClipboard, FaFileMedical } from "react-icons/fa6";
import { baseUrl } from '../../utils/constant';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import LineChart from '../../components/chart/LineChart';

const Overview = () => {

  const { appointments } = useSelector((state) => state.dashboard);

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const filteredAppointments = () => {
    const todayAppointments = [];
    appointments?.forEach(appointment => {
      console.log(appointment.date === today);
      if (appointment.date === today) {
        todayAppointments.push(appointment);
      }
      return;
    });

    return { todayAppointments };
  };

  const { todayAppointments } = filteredAppointments();

  useEffect(() => {
    const getStats = async () => {
      try {
        const res = await axios.get(`${baseUrl}/stats.php`);
        console.log(res.data); // Log data for debugging

        if (res.status === 200) {
          setStats(res.data); // Update stats state with the API response
        }
      } catch (err) {
        console.error('Error fetching stats:', err);
        setError('Failed to fetch stats.'); // Set error if the request fails
      } finally {
        setLoading(false); // Set loading to false regardless of success/failure
      }
    };

    getStats();
    const { todayAppointments } = filteredAppointments();
  }, []);



  return (
    <main className='w-full h-full px-5 py-5'>
      <div className='w-full h-full flex gap-5 '>
        <section className='w-3/4 h-full flex flex-col gap-5'>
          <div className='w-full h-4/6 flex flex-col gap-5 '>
            <div className='flex gap-5 h-2/4'>
              <div className='bg-white p-5 w-2/6 rounded-lg flex items-center justify-evenly'>
                <div>
                  <FaBedPulse className='text-slate-500 text-4xl' />
                </div>
                <div>
                  <p className='font-bold capitalize text-sm text-slate-500'>total bed spaces</p>
                  <p className='font-extrabold capitalize text-2xl text-appColor'>{stats?.inpatients}/100</p>
                </div>
              </div>
              <div className='bg-white p-5 w-2/6 rounded-lg flex items-center justify-evenly'>
                <div>
                  <FaUserDoctor className='text-slate-500 text-4xl' />
                </div>
                <div>
                  <p className='font-bold capitalize text-sm text-slate-500'>total Doctors</p>
                  <p className='font-extrabold capitalize text-3xl text-appColor'>{stats?.doctors}</p>
                </div>
              </div>
              <div className='bg-white p-5 w-2/6 rounded-lg flex items-center justify-evenly'>
                <div>
                  <FaUserInjured className='text-slate-500 text-4xl' />
                </div>
                <div>
                  <p className='font-bold capitalize text-sm text-slate-500'>total patients</p>
                  <p className='font-extrabold capitalize text-3xl text-appColor'>{stats?.patients}</p>
                </div>
              </div>

              <div className='bg-white p-5 w-2/6 rounded-lg flex items-center justify-evenly'>
                <div>
                  <FaUserNurse className='text-slate-500 text-4xl' />
                </div>
                <div>
                  <p className='font-bold capitalize text-sm text-slate-500'>total Staffs</p>
                  <p className='font-extrabold capitalize text-3xl text-appColor'>{stats?.staffs}</p>
                </div>
              </div>

            </div>

            <div className='flex gap-5 h-full'>
              <div className='bg-white p-5 w-3/4 rounded-lg'></div>
              <div className='bg-white p-5 w-2/4 rounded-lg'></div>
            </div>
          </div>
          <div className='w-full h-[400px] bg-white rounded-lg flex flex-col'>
            <div className='bg-appColor p-2 font-bold text-white rounded-t-lg capitalize'>
              <h2>patient activity</h2>
            </div>
            <div className="flex-1 p-4">
              <LineChart data={stats?.newpatients} />
            </div>
          </div>
        </section>
        <section className='w-1/4 h-full flex flex-col gap-5'>
          <div className='h-2/4 bg-white rounded-lg'>
            <div className='bg-appColor p-2 font-bold text-white rounded-t-lg capitalize'>
              <h2>upcoming appointments</h2>
            </div>
            <div className="p-4 flex flex-col gap-2 overflow-auto">
              {todayAppointments.length > 0 ? (
                todayAppointments.map((item, i) => (
                  <div key={i} className="shadow-md bg-appColor bg-opacity-25 rounded-md p-2 flex gap-2 w-full justify-between items-center ">
                    <p className="font-semibold capitalize">{`${item.firstname} ${item.lastname}`}</p>
                    <p>{item.time}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No appointments for today.</p>
              )}
            </div>
          </div>
          <div className='h-2/4 bg-white rounded-lg'>
            <div className='bg-appColor p-2 font-bold text-white rounded-t-lg capitalize'>
              <h2>reports</h2>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default Overview