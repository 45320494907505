import React, { useState, useEffect } from 'react';
import { MdEdit, MdDelete, MdAdd } from "react-icons/md";
import Table from '../../../components/Table';
import AddAppointment from './AddAppointment';
import DeleteAppointment from './DeleteAppointment';
import EditAppointment from './EditAppointment';
import axios from 'axios';
import { baseUrl } from '../../../utils/constant';
import { useDispatch, useSelector } from "react-redux";
import { setAppointments } from '../../../redux/Features/Dashboard';

const Appointments = () => {
  const dispatch = useDispatch();
  const { appointments } = useSelector((state) => state.dashboard);

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const openModal = (modalName, id) => {
    setSelectedId(id);
    switch (modalName) {
      case 'add':
        setIsOpenAddModal(true);
        break;
      case 'edit':
        setIsOpenEditModal(true);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        break;
      default:
        console.error('Invalid modal name');
    }
  };

  const closeModal = (modalName) => {
    switch (modalName) {
      case 'add':
        setIsOpenAddModal(false);
        break;
      case 'edit':
        setIsOpenEditModal(false);
        break;
      case 'delete':
        setIsOpenDeleteModal(false);
        break;
      default:
        console.error('Invalid modal name');
    }
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  const today = `${year}-${month}-${day}`;

  const filteredAppointments = () => {
    const todayAppointments = [];
    const pastAppointments = [];

    appointments.forEach(appointment => {
      const appointmentDate = new Date(appointment.date);
      if (appointment.date === today) {
        todayAppointments.push(appointment);
      } else if (appointmentDate < new Date(today)) {
        pastAppointments.push(appointment);
      }
    });

    return { todayAppointments, pastAppointments };
  };

  const { todayAppointments, pastAppointments } = filteredAppointments();

  const addNewAppointment = (newAppointment) => {
    dispatch(setAppointments([...appointments, newAppointment]));
  };
  

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const res = await axios.get(`${baseUrl}/appointment.php`);
        dispatch(setAppointments(res.data.data));
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };
    fetchAppointments();
  }, [dispatch]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'FULL NAME',
      selector: row => `${row.firstname} ${row.lastname}`,
      sortable: true,
    },
    {
      name: 'TYPE',
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'DATE',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'TIME',
      selector: row => row.time,
      sortable: true,
    },
    {
      name: 'CODE',
      selector: row => row.booking_number,
      sortable: true,
    },
    {
      name: 'ACTIONS',
      cell: row => (
        <div className='flex gap-4'>
          <button
            onClick={() => openModal("edit", row.id)}
            className="border-2 border-green-700 hover:bg-green-300 text-white font-bold text-sm rounded-md px-1 py-1 focus:outline-none"
          >
            <MdEdit className='text-xl text-green-700' />
          </button>
          <button
            onClick={() => openModal("delete", row.id)}
            className="border-2 border-red-700 hover:bg-red-300 text-white font-bold text-sm rounded-md px-1 py-1 focus:outline-none"
          >
            <MdDelete className='text-xl text-red-700' />
          </button>
        </div>
      ),
    },
  ];

  return (
    <main className='w-full h-full px-5 py-3 flex gap-5'>
      <section className="bg-white w-full h-full rounded-lg px-5">
        <Table
          title="Appointments"
          label={columns}
          filter={"booking_number"}
          data={appointments}
          children={
            <button
              onClick={() => openModal("add")}
              className="bg-appColor flex items-center gap-2 text-white font-bold text-sm rounded-md px-3 py-1 focus:outline-none"
            >
              <MdAdd className='text-white' /> <p>Add Appointment</p>
            </button>
          }
        />
      </section>

      <section className="w-2/6 h-full flex flex-col gap-5">
        {/* Today's Appointments */}
        <div className="bg-white h-2/4 rounded-lg">
          <div className='w-full bg-appColor text-white font-bold capitalize p-2 rounded-t-lg'>
            <h2>Today's Appointments</h2>
          </div>
          <div className="p-4 flex flex-col gap-2 overflow-auto">
            {todayAppointments.length > 0 ? (
              todayAppointments.map((item, i) => (
                <div key={i} className="shadow-md bg-appColor bg-opacity-25 rounded-md p-2 flex gap-2 w-full justify-between items-center ">
                  <p className="font-semibold capitalize">{`${item.firstname} ${item.lastname}`}</p>
                  <p>{item.time}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No appointments for today.</p>
            )}
          </div>
        </div>

        {/* Past Appointments */}
        <div className="bg-white h-2/4 rounded-lg">
          <div className='w-full bg-appColor text-white font-bold capitalize p-2 rounded-t-lg'>
            <h2>Past Appointments</h2>
          </div>
          <div className="p-4 flex flex-col gap-2 overflow-auto">
            {pastAppointments.length > 0 ? (
              pastAppointments.map((item, i) => (
                <div key={i} className="shadow-md bg-appColor bg-opacity-25 rounded-md p-2 flex gap-2 w-full justify-between items-center ">
                  <p className="font-semibold capitalize">{`${item.firstname} ${item.lastname}`}</p>
                  <p>{item.date}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500 capitalize">No appointments.</p>
            )}
          </div>
        </div>
      </section>

      {/* Modals for Add, Edit, Delete */}
      {isOpenAddModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <AddAppointment closeAddModal={() => closeModal("add")} addNewAppointment={addNewAppointment } />
            </div>
          </div>
        </div>
      )}

      {isOpenEditModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <EditAppointment selectedId={selectedId} closeEditModal={() => closeModal("edit")} />
            </div>
          </div>
        </div>
      )}

      {isOpenDeleteModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <DeleteAppointment selectedId={selectedId} closeDeleteModal={() => closeModal("delete")} />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Appointments;
