import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { baseUrl } from '../../../../utils/constant';
import { MdRemoveRedEye, MdDelete } from "react-icons/md";
import Table from '../../../../components/Table';
import ViewMedical from './ViewMedical';
import DeleteMedical from './DeleteMedical';

const RecordsMedical = () => {
  const { patientDetails } = useSelector((state) => state.patient);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.get(`${baseUrl}/record.php?ref=${patientDetails?.ref}`);
        console.log(res.data);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
  }, [patientDetails?.ref]);

  const openModal = (modalName, id = null) => {
    setSelectedId(id);
    if (modalName === 'edit') {
      setIsOpenEditModal(true);
    } else if (modalName === 'delete') {
      setIsOpenDeleteModal(true);
    }
  };

  const closeModal = (modalName) => {
    if (modalName === 'edit') {
      setIsOpenEditModal(false);
    } else if (modalName === 'delete') {
      setIsOpenDeleteModal(false);
    }
    setSelectedId(null);
  };

  const columns = [
    { name: 'PATIENTS REF NO', selector: row => row.ref, sortable: true },
    { name: 'STAFF', selector: row => row.staff, sortable: true },
    { name: 'TYPE', selector: row => row.type, sortable: true },
    { name: 'DATE', selector: row => row.created_at, sortable: true },
    {
      name: 'ACTIONS',
      cell: row => (
        <div className='flex gap-4'>
          <button
            onClick={() => openModal("edit", row.id)}
            className="border-2 border-green-700 hover:bg-green-300 text-white font-bold text-sm rounded-md px-1 py-1 focus:outline-none"
          >
            <MdRemoveRedEye className='text-xl text-green-700' />
          </button>

          <button
            onClick={() => openModal("delete", row.id)}
            className="border-2 border-red-700 hover:bg-red-300 text-white font-bold text-sm rounded-md px-1 py-1 focus:outline-none"
          >
            <MdDelete className='text-xl text-red-700' />
          </button>
        </div>
      ),
    },
  ];

  return (
    <main className='w-full h-full overflow-auto bg-white rounded-lg shadow-md p-4'>
      <Table
        label={columns}
        filter={"ref"}
        showFilter={true}
        data={data}
      />

      {/* Edit Modal */}
      {isOpenEditModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-500 opacity-80"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-lg sm:w-full">
              <ViewMedical closeEditModal={() => closeModal("edit")} id={selectedId} />
            </div>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {isOpenDeleteModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-500 opacity-40"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <DeleteMedical closeDeleteModal={() => closeModal("delete")} id={selectedId} />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default RecordsMedical;
