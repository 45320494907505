import React, { useState } from 'react';
import Loader from "../../../../components/Loader";
import Alert from "../../../../components/Alert";
import { baseUrl } from '../../../../utils/constant';
import axios from "axios";

const ResetPass = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEmailVerification = async () => {
        if (formData.email === "")
            if (!formData.email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
                setError("Please enter a valid email address.");
                return;
            }

        setLoading(true);
        setError(""); // Clear previous errors
        setSuccessMessage(""); // Clear previous success message

        try {
            const res = await axios.post(`${baseUrl}/auth.php?action=verify`, formData);
            console.log(res);

            if (res.data.status === 'success') {
                setIsEmailVerified(true); // Set email as verified if response is successful
                setSuccessMessage("Email verified successfully.");
                setLoading(false);
            } else {
                setError(res.data.message || "Email verification failed.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setError("An error occurred during verification. Please try again.");
        }
    };

    const handleResetPassword = async () => {
        setError(""); // Clear previous errors
        setSuccessMessage(""); // Clear previous success message

        setLoading(true);

        if (!formData.password || !formData.confirmPassword) {
            setError("Both password fields are required.");
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const res = await axios.post(`${baseUrl}/auth.php?action=updatePassword`, formData);
            console.log(res.data.status);


            if (res.data.status === 'success') {
                setSuccessMessage("Password reset successfully.");
                setLoading(false);
            } else {
                setError(res.data.message || "Password reset failed.");
                setLoading(false);
            }
        } catch (error) {
            setError("An error occurred during password reset. Please try again.");
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='w-6/12'>
            <h1 className='uppercase text-appColor text-4xl font-extrabold'>reset account</h1>
            <p className='capitalize'>enter email address to reset account</p>


            {/* Email input shown only if the email is not verified */}
            {!isEmailVerified && (
                <div className='flex gap-3 my-5'>
                    <div className="w-full">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="border-2 focus:border-blue-500 p-2 block w-full sm:text-sm border-appColor rounded-md"
                            placeholder="Enter Email Address"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <button
                        className='p-2 px-3 bg-appColor rounded-md text-white font-bold capitalize'
                        onClick={handleEmailVerification}
                    >
                        verify
                    </button>
                </div>
            )}

            {/* Password fields shown only if the email is verified */}
            {isEmailVerified && (
                <div className='flex flex-col gap-4 justify-center items-end'>
                    <div className="w-full">
                        <label htmlFor="password" className='text-appColor font-bold capitalize'>password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="border-2 focus:border-blue-500 p-2 block w-full sm:text-sm border-appColor rounded-md"
                            placeholder="Enter New Password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="confirmPassword" className='text-appColor font-bold capitalize'>confirm password</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            className="border-2 focus:border-blue-500 p-2 block w-full sm:text-sm border-appColor rounded-md"
                            placeholder="Confirm New Password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <button
                        className='p-2 px-3 bg-appColor rounded-md text-white font-bold capitalize'
                        onClick={handleResetPassword}
                    >
                        reset password
                    </button>
                </div>
            )}

            <p>{error}</p>
        </div>
    )
}

export default ResetPass;
