import React, { useState } from 'react';
import axios from 'axios';
import { MdOutlineAdd } from 'react-icons/md';
// import { baseApiUrl } from '../../../utils/constants';

const AddEvent = ({ closeAddModal }) => {
    const [formData, setFormData] = useState({
        title: '',
        start: '',
        end: '',
        allDay: false
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.title) tempErrors.title = 'Title is required';
        if (!formData.start) tempErrors.start = 'Start date is required';
        if (!formData.end) tempErrors.end = 'End date is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const save = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            console.log(formData);
            // Replace with your base API URL
            // const response = await axios.post(`${baseApiUrl}/event.php`, formData);

            // Mock response to simulate success
            const response = { status: 200 };

            if (response.status === 200) {
                console.log('Event saved successfully');
                closeAddModal();
            } else {
                console.error('Failed to save event');
            }
        } catch (error) {
            console.error('An error occurred while saving the event', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className=''>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-start">
                    <div className='flex gap-4'>
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <MdOutlineAdd className='text-green-500 text-base' />
                        </div>
                        <h3 className="text-lg mt-2 leading-6 font-bold uppercase text-slate-800" id="modal-title">Add Event</h3>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <form className="flex flex-col gap-4 mt-3">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div className="w-full">
                                    <label htmlFor="title">TITLE</label>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Event Title"
                                        value={formData.title}
                                        onChange={handleChange}
                                        className={`border-2 ${errors.title ? 'border-red-500' : 'focus:border-blue-500'} p-2 block w-full sm:text-sm border-slate-300 rounded-md`}
                                    />
                                    {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="title">START</label>
                                    <input
                                        type="datetime-local"
                                        name="start"
                                        value={formData.start}
                                        onChange={handleChange}
                                        className={`border-2 ${errors.start ? 'border-red-500' : 'focus:border-blue-500'} p-2 block w-full sm:text-sm border-slate-300 rounded-md`}
                                    />
                                    {errors.start && <p className="text-red-500 text-sm mt-1">{errors.start}</p>}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="title">End</label>
                                    <input
                                        type="datetime-local"
                                        name="end"
                                        value={formData.end}
                                        onChange={handleChange}
                                        className={`border-2 ${errors.end ? 'border-red-500' : 'focus:border-blue-500'} p-2 block w-full sm:text-sm border-slate-300 rounded-md`}
                                    />
                                    {errors.end && <p className="text-red-500 text-sm mt-1">{errors.end}</p>}
                                </div>
                                <div className="w-full flex items-center">
                                    <input
                                        type="checkbox"
                                        name="allDay"
                                        checked={formData.allDay}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    <label htmlFor="allDay">All Day Event</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    onClick={save}
                    disabled={loading}
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${loading ? 'bg-green-300' : 'bg-green-500 hover:bg-green-400'
                        } text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                    {loading ? 'Saving...' : 'Save'}
                </button>
                <button
                    type="button"
                    onClick={closeAddModal}
                    className="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AddEvent;
