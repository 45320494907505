import React from 'react'
import Header from '../components/Header'
import iphone from '../assets/Iphone.png'
import galaxy from '../assets/Galaxy.png'

const Download = () => {
  return (
    <div className='w-full'>
      <Header />
      <section className='p-5'>
        <div>
          <p className='font-extrabold capitalize text-center'>download now</p>
          <h1 className='text-4xl md:text-6xl font-extrabold text-center text-appColor capitalize'>take charge of your health <br /> download now</h1>
          <div className='flex gap-10 items-center justify-center my-5'>
            <button className='p-2 border-2 border-appColor rounded-lg'>download</button>
            <button className='p-2 border-2 border-appColor rounded-lg'>download</button>
          </div>
        </div>
        <div className='flex gap-5 h-full items-center justify-center'>
          <img src={iphone} alt="" className='h-[300px] md:h-[400px]' />
          <img src={galaxy} alt="" className='h-[300px] md:h-[400px]' />
        </div>
      </section>
    </div>
  )
}

export default Download