import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { baseUrl } from '../../../utils/constant';
import Alert from "../../../components/Alert";

const Remark = () => {

    const { data } = useSelector((state) => state.user);
    const staff = data.firstname + " " + data.lastname;

    console.log(staff);



    const [formData, setFormData] = useState({
        ref: "",
        type: "",
        remark: "",
        staff: staff,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleSumbit = async (e) => {
        e.preventDefault();
        console.log(formData);

        try {
            const res = await axios.post(`${baseUrl}/record.php`, formData);
            if (res.status === 201) {
                Alert("success", "RECORD SAVED")
            } else {
                Alert("error", "Failed to save record")
            }
        } catch (error) {
            console.log(error);
            Alert("error", error);
        }
    }



    return (
        <main >
            <form action="" className='flex flex-col gap-4' >
                <div className='grid grid-cols-3 gap-4'>
                    <div className="w-full">
                        <label htmlFor="ref" className='uppercase font-bold text-appColor'>reference number</label>
                        <input
                            type="text"
                            name="ref"
                            id="ref"
                            className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                            placeholder="Patients Reference Number"
                            value={formData.ref}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="type" className='uppercase font-bold text-appColor'>type of report</label>
                        <input
                            type="text"
                            name="type"
                            id="type"
                            className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                            placeholder="TYPE"
                            value={formData.type}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <label htmlFor="type" className='uppercase font-bold text-appColor'>type of report</label>
                    <textarea onChange={handleChange} name="remark" placeholder='REMARK' className='p-4 rounded-lg' rows={10} id="remark"></textarea>
                </div>
                <button onClick={handleSumbit} className='bg-appColor text-white font-bold px-3 p-2 rounded-lg capitalize w-max '>Send Report</button>
            </form>
        </main>
    )
}

export default Remark

