import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseUrl } from '../../../utils/constant';
import axios from 'axios';
import Alert from "../../../components/Alert";

const Prescription = () => {
    const { data } = useSelector((state) => state.user);
    const staff = data.firstname + " " + data.lastname;

    const [formData, setFormData] = useState({
        ref: "",
        presc: "",
        doc: staff,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSumbit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/prescriptions.php`, formData);
            console.log(res);
            if (res.status === 201) {
                Alert("success", "Done")
            } else {
                Alert("error", "Failed")
            }
        } catch (error) {
            console.log(error);
            Alert("error", error)

        }
    }

    return (
        <div className='p-5'>
            <div className='flex items-center justify-start mb-10'>
                <div className="w-2/4">
                    <input
                        type="text"
                        name="ref"
                        id="ref"
                        className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                        placeholder="Patients Reference Number"
                        value={formData.ref}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <form action="" className='grid grid-cols-1 gap-10'>
                <textarea name="presc" onChange={handleChange} placeholder='Prescription' className='rounded-2xl p-3' rows={6} id=""></textarea>
            </form>
            <div className='flex items-center justify-end mt-10'>
                <button onClick={handleSumbit} className='bg-appColor text-white font-bold px-3 p-2 rounded-lg capitalize w-max '>Save</button>
            </div>
        </div >
    )
}

export default Prescription;

